import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Importing images
import logo from "../../../images/home/section-one/logo.png"
import phone from "../../../images/home/section-one/space-grey.png"

// Typwriter imports
import Typical from "react-typical"

// Phone Animation imports
import { useSpring, animated } from "react-spring"
//Animation calcs
const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 60,
  (x - window.innerWidth / 2) / 60,
  1.05,
]
const trans = (x, y, s) =>
  `perspective(1200px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

// Start of component
const SectionOne = () => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 10, tension: 200, friction: 40 },
  }))

  return (
    <Container>
      <Left>
        <InnerContainer>
          <Logo />
          <Typical
            steps={[
              "Fest uden at skændes om musikken",
              1000,
              "Fest, Fest - Test, Test",
              1000,
              "Tredje gang er lykkens gang",
              1000,
            ]}
            loop={Infinity}
            wrapper="h1"
          />
          <StyledLink to="/home">
            <Button>
              Join nu{" "}
              <span role="img" aria-label="pointing-right">
                👉
              </span>
            </Button>
          </StyledLink>
        </InnerContainer>
      </Left>
      <Right>
        <AbsoluteDiv
          onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
          onMouseLeave={() => set({ xys: [0, 0, 1] })}
        />
        <ImageContainer>
          <Phone style={{ transform: props.xys.interpolate(trans) }} />
        </ImageContainer>
      </Right>
    </Container>
  )
}

export default SectionOne

const Container = styled.section`
  width: 100%;
  height: auto;

  color: #fff;

  display: block;

  @media screen and (min-width: 480px) {
    display: grid;

    height: 100vh;
    max-height: 100vh;

    grid-template-rows: 1fr;
    grid-row-gap: 0;

    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5%;
  }

  @media screen and (min-width: 768px) {
  }

  @media screen and (min-width: 1612px) {
    grid-column-gap: 10%;
  }
`

// Left section
const Left = styled.div`
  width: 100%;
  height: 40vh;

  padding-top: 50px;
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 480px) {
    height: 100vh;
  }

  @media screen and (min-width: 768px) {
  }
`

const InnerContainer = styled.div`
  width: auto;
  max-width: 768px;
  height: 100%;
  padding-left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 480px) {
    padding-left: 20%;
  }

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }

  // Typewriter
  h1 {
    min-height: 30px;

    font-weight: bold;
    font-size: 28px;
    margin: 50px 10px 50px 10px;
    text-align: center;

    @media screen and (min-width: 480px) {
      font-size: 32px;
    }

    @media screen and (min-width: 768px) {
      font-size: 48px;
      text-align: left;
      min-height: 110px;
    }
  }
`

// Image stuff
const Image = styled.img`
  width: auto;
  height: auto;
  max-height: 100%;

  margin: 0;
`

const Logo = styled(Image)`
  content: url(${logo});
  max-width: 65%;
  height: auto;
`
// Button stuff
const Button = styled.div`
  width: 100px;
  height: 33px;
  font-size: 16px;
  font-weight: bold;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  -moz-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);

  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 480px) {
    width: 150px;
    height: 48px;
    font-size: 20px;
  }

  @media screen and (min-width: 768px) {
    width: 200px;
    height: 66px;
    font-size: 30px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-size: 16px;
  color: #333;

  @media screen and (min-width: 480px) {
    font-size: 20px;
  }

  @media screen and (min-width: 768px) {
    font-size: 30px;
  }
`

/*
-
---------------------------------------------------------------------------
-
*/

// Right section
const Right = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 480px) {
    max-height: 130vh;
  }

  @media screen and (min-width: 768px) {
  }
`

const AbsoluteDiv = styled(animated.div)`
  width: 130%;
  height: 100%;

  position: absolute;
  z-index: 10;
  right: 30%;
`

const ImageContainer = styled(animated.div)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 480px) {
    position: relative;
  }

  @media screen and (min-width: 768px) {
  }
`

const Phone = styled(animated.img)`
  content: url(${phone});
  width: auto;
  height: auto;
  max-height: 80%;
  margin: 0;
  padding: 30px;
  position: static;

  @media screen and (min-width: 480px) {
    padding: 0;
  }

  @media screen and (min-width: 768px) {
  }
`
